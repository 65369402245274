import React from "react";
import "../styles/Modal.css";

const Modal = ({ content, closeModal }) => {
    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={closeModal}>
                    &times;
                </button>
                {content}
            </div>
        </div>
    );
};

export default Modal;
