import React from "react";

const DatenschutzerklaerungContent = () => {
    return (
        <div>
            <h3>Datenschutzerklärung</h3>

            <p>
                Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer
                persönlichen Daten ist uns wichtig. Nachfolgend informieren wir Sie über die
                Verarbeitung personenbezogener Daten bei der Nutzung unserer Website.
            </p>

            <h4>1. Verantwortlicher</h4>
            <p>
                Vladimir Gaus<br />
                34393 Grebenstein<br />
                Deutschland<br />
                E-Mail: venic89@gmail.com
            </p>

            <h4>2. Erhobene Daten</h4>
            <p>
                Bei der Nutzung unseres Kontaktformulars und beim Besuch unserer Website
                werden folgende personenbezogene Daten verarbeitet:
            </p>
            <ul>
                <li>Name</li>
                <li>E-Mail-Adresse</li>
                <li>Nachricht</li>
                <li>IP-Adresse (durch Drittanbieter wie hCaptcha oder Server-Logdateien)</li>
            </ul>

            <h4>3. Zweck der Datenverarbeitung</h4>
            <p>
                Die Datenverarbeitung erfolgt zu folgenden Zwecken:
            </p>
            <ul>
                <li>Bearbeitung Ihrer Anfragen über das Kontaktformular</li>
                <li>Schutz vor Missbrauch und Spam (z. B. durch hCaptcha)</li>
                <li>Sicherstellung des technischen Betriebs der Website</li>
            </ul>

            <h4>4. Eingesetzte Drittanbieter</h4>
            <p>
                Wir setzen folgende Drittanbieter ein. Hier finden Sie deren
                Datenschutzrichtlinien:
            </p>
            <ul>
                <li>
                    <strong>GitHub Pages</strong> (Hosting der Website):{" "}
                    <a
                        href="https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <strong>Heroku</strong> (Backend-Hosting):{" "}
                    <a
                        href="https://www.salesforce.com/company/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Heroku Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <strong>Mailgun</strong> (E-Mail-Dienst):{" "}
                    <a
                        href="https://www.mailgun.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Mailgun Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <strong>hCaptcha</strong> (Spam-Schutz):{" "}
                    <a
                        href="https://www.hcaptcha.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        hCaptcha Datenschutzerklärung
                    </a>
                </li>
            </ul>

            <h4>5. Logdateien</h4>
            <p>
                Beim Zugriff auf unsere Website werden automatisch Informationen in Server-Logdateien erfasst. Diese Daten umfassen:
            </p>
            <ul>
                <li>IP-Adresse des zugreifenden Geräts</li>
                <li>Datum und Uhrzeit des Zugriffs</li>
                <li>Browsertyp und -version</li>
                <li>Verwendetes Betriebssystem</li>
                <li>Referrer-URL (zuvor besuchte Seite)</li>
            </ul>
            <p>
                Diese Daten werden ausschließlich zur Sicherstellung des technischen Betriebs und zur Verbesserung unseres Angebots verwendet.
            </p>

            <h4>6. Eingebettete Inhalte von Drittanbietern</h4>
            <p>
                Auf unserer Website können Inhalte von Drittanbietern eingebettet sein, wie z. B. YouTube-Videos, LinkedIn-Profile oder GitHub-Projekte. Beim Laden dieser Inhalte können personenbezogene Daten an die jeweiligen Anbieter übertragen werden. 
                Mehr Informationen finden Sie in den Datenschutzrichtlinien der jeweiligen Anbieter:
            </p>
            <ul>
                <li>
                    <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        YouTube Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.linkedin.com/legal/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LinkedIn Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <a
                        href="https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Datenschutzerklärung
                    </a>
                </li>
            </ul>

            <h4>7. Ihre Rechte</h4>
            <p>Sie haben folgende Rechte gemäß DSGVO:</p>
            <ul>
                <li>Recht auf Auskunft (Art. 15 DSGVO)</li>
                <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
                <li>Recht auf Löschung (Art. 17 DSGVO)</li>
                <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</li>
                <li>
                    Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO):{" "}
                    <a
                        href="https://www.bfdi.bund.de/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Bundesbeauftragter für den Datenschutz
                    </a>
                </li>
            </ul>

            <h4>8. Speicherdauer</h4>
            <p>
                Ihre personenbezogenen Daten werden nach Abschluss der Bearbeitung Ihrer
                Anfrage gelöscht, es sei denn, gesetzliche Aufbewahrungsfristen schreiben
                eine längere Speicherung vor.
            </p>

            <h4>9. Sicherheit</h4>
            <p>
                Wir setzen technische und organisatorische Maßnahmen ein, um Ihre Daten
                gegen Verlust, Manipulation und unberechtigten Zugriff zu schützen.
            </p>

            <h4>10. Änderungen dieser Datenschutzerklärung</h4>
            <p>
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um aktuellen rechtlichen Anforderungen zu entsprechen oder Änderungen an unseren Diensten umzusetzen. Die jeweils aktuelle Version ist auf dieser Seite einsehbar.
            </p>

            <h4>11. Kontakt</h4>
            <p>
                Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten oder zur Wahrnehmung Ihrer Rechte können Sie uns jederzeit unter <strong>venic89@gmail.com</strong> kontaktieren.
            </p>
        </div>
    );
};

export default DatenschutzerklaerungContent;
