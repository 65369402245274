import React from "react";
import "../styles/Skills.css";
import {
    FaPython,
    FaDatabase,
    FaBrain,
    FaShieldAlt,
    FaNetworkWired,
    FaBug,
    FaKey,
    FaFireAlt,
    FaLock,
    FaUserShield,
    FaChartBar,
} from "react-icons/fa";
import {
    SiTensorflow,
    SiPandas,
    SiMysql,
    SiApache,
    SiGooglecloud,
    SiSplunk,
    SiTableau,
} from "react-icons/si";

const Skills = ({ data }) => {
    const formatCategoryName = (category) => {
        return category
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const skillIcons = {
        "Netzwerksicherheit": <FaNetworkWired />,
        "Penetration Testing": <FaShieldAlt />,
        "Incident Response": <FaUserShield />,
        "Cryptography": <FaLock />,
        "Firewalls": <FaFireAlt />,
        "Malware Analysis": <FaBug />,
        "Public Key Infrastructure (PKI)": <FaKey />,
        "Encryption Algorithms": <FaLock />,
        "SIEM": <SiSplunk />,
        "Python": <FaPython />,
        "Machine Learning": <FaBrain />,
        "SQL": <SiMysql />,
        "Data Wrangling": <SiPandas />,
        "Data Visualization": <SiTableau />,
        "Data Analysis": <FaChartBar />,
        "Deep Learning": <SiTensorflow />,
        "Big Data": <SiApache />,
        "Cloud Computing": <SiGooglecloud />,
    };

    return (
        <section className="skills" id="skills">
            <h2 className="skills-title">Fähigkeiten</h2>
            <div className="skills-container">
                {Object.keys(data).map((category) => (
                    <div key={category} className="skills-category">
                        <h3 className="skills-category-title">{formatCategoryName(category)}</h3>
                        <div className="skills-list">
                            {data[category].map((skill) => (
                                <div key={skill.name} className="skill-item">
                                    <div className="skill-name">
                                        {skillIcons[skill.name] || <FaDatabase />}
                                        {skill.name}
                                    </div>
                                    <div className="skill-bar">
                                        <div
                                            className="skill-progress"
                                            style={{
                                                width: `${skill.proficiency}%`,
                                                "--progress-width": `${skill.proficiency}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="skill-percentage">{skill.proficiency}%</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Skills;
