import React, { useState } from "react";
import "./../styles/Hero.css";
import cybersecurityVideoMp4 from "../assets/videos/cybersecurity.mp4";
import cybersecurityVideoWebm from "../assets/videos/cybersecurity.webm"; 
import { FaLinkedin, FaXing, FaGithub } from "react-icons/fa";

const Hero = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoaded = () => {
        setIsVideoLoaded(true); 
    };

    const isMobileDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <section className="hero" id="hero">
            
            {!isVideoLoaded && <div className="preloading-overlay"></div>}

            
            {!isMobileDevice() && (
                <video
                    className={`hero-video ${isVideoLoaded ? "visible" : "hidden"}`}
                    autoPlay
                    loop
                    muted
                    preload="metadata" 
                    playsInline
                    onCanPlay={handleVideoLoaded} 
                >
                    <source src={cybersecurityVideoWebm} type="video/webm" />
                    <source src={cybersecurityVideoMp4} type="video/mp4" />
                    Ihr Browser unterstützt das Video-Tag nicht.
                </video>
            )}

            <div className="hero-overlay"></div>

            <div className="hero-content">
                <h1 data-aos="fade-up">Hallo, ich bin Vladimir Gaus</h1>
                <p data-aos="fade-up" data-aos-delay="200">
                    Ein ambitionierter <span className="highlight">Cybersecurity Analyst</span> und{" "}
                    <span className="highlight">Data Scientist</span>, der mit Leidenschaft innovative Technologien erforscht und sich kontinuierlich weiterentwickelt.
                </p>
                <p data-aos="fade-up" data-aos-delay="300">
                    Noch bin ich kein Experte, aber ich arbeite zielstrebig daran, meine Fähigkeiten auszubauen und praktische Erfahrungen zu sammeln.
                </p>

                <div className="hero-buttons" data-aos="fade-up" data-aos-delay="400">
                    <a href="#about" className="btn primary">Über mich</a>
                    <a href="#contact" className="btn primary">Kontakt aufnehmen</a>
                </div>

                <div className="hero-icons">
                    <a href="https://www.linkedin.com/in/vladimir-gaus-7408a6177" target="_blank" rel="noopener noreferrer" className="hero-icon" title="LinkedIn">
                        <FaLinkedin />
                    </a>
                    <a href="https://www.xing.com/profile/Vladimir_Gaus" target="_blank" rel="noopener noreferrer" className="hero-icon" title="Xing">
                        <FaXing />
                    </a>
                    <a href="https://github.com/GausCode" target="_blank" rel="noopener noreferrer" className="hero-icon" title="GitHub">
                        <FaGithub />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;
