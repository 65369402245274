import React from "react";
import "./../styles/About.css";
import profileImage from "../assets/images/profile.jpg";

const About = () => {
    return (
        <section className="about" id="about">
            <div className="about-container">
                <h2 className="about-title" data-aos="fade-down">Über mich</h2>
                <p className="about-subtitle" data-aos="fade-up" data-aos-delay="100">Wer ich bin und was ich tue</p>

                <img 
                    src={profileImage} 
                    alt="Vladimir Gaus" 
                    className="about-image" 
                    data-aos="zoom-in" 
                    data-aos-delay="200" 
                />

                <p className="about-text" data-aos="fade-up" data-aos-delay="300">
                    Hallo, ich bin Vladimir Gaus, ein begeisterter Cybersecurity Analyst und Data Scientist.
                    Mit einem Fokus auf moderne Technologien helfe ich dabei, Systeme zu schützen und 
                    datengetriebene Lösungen zu entwickeln.
                </p>
                <p className="about-text" data-aos="fade-up" data-aos-delay="400">
                    Lassen Sie uns zusammenarbeiten, um eine sichere und datengesteuerte Zukunft zu schaffen!
                </p>
            </div>
        </section>
    );
};

export default About;
