import axios from "axios";

const API = axios.create({
    baseURL: "https://portfolionewbackend-d3e88d27ef4b.herokuapp.com",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
    },
});

export default API;

