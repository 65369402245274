import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import Certificates from "./components/Certificates";
import Skills from "./components/Skills";
import ContactForm from "./components/ContactForm";
import CookieBanner from "./components/CookieBanner";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import ImpressumContent from "./components/ImpressumContent";
import DatenschutzerklaerungContent from "./components/DatenschutzerklaerungContent";
import API from "./api";
import "./styles/App.css";

const App = () => {
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [projects, setProjects] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const openModal = (type) => {
        setModalOpen(true);
        if (type === "impressum") {
            setModalContent(<ImpressumContent />);
        } else if (type === "datenschutz") {
            setModalContent(<DatenschutzerklaerungContent />);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        console.log("Fetching data from API...");
        const fetchData = async () => {
            try {
                const [projectsResponse, certificatesResponse, skillsResponse] = await Promise.all([
                    API.get("/api/projects/"),
                    API.get("/api/certificates/"),
                    API.get("/api/skills/"),
                ]);

                setProjects(projectsResponse.data);
                setCertificates(certificatesResponse.data);

                const groupedSkills = skillsResponse.data.reduce((acc, skill) => {
                    if (!acc[skill.category]) acc[skill.category] = [];
                    acc[skill.category].push(skill);
                    return acc;
                }, {});

                Object.keys(groupedSkills).forEach((category) => {
                    groupedSkills[category].sort((a, b) => b.proficiency - a.proficiency);
                });

                setSkills(groupedSkills);
                setLoading(false);
            } catch (err) {
                console.error("Fehler beim Abrufen der Daten:", err);
                setError("Daten konnten nicht geladen werden.");
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="navbar-placeholder"></div>
            <Hero />
            <About />
            {loading ? (
                <p>Daten werden geladen...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <>
                    <Projects data={projects} />
                    <Certificates data={certificates} />
                    <Skills data={skills} />
                </>
            )}
            <ContactForm />
            <CookieBanner />
            <Footer openModal={openModal} />
            {isModalOpen && <Modal content={modalContent} closeModal={closeModal} />}
        </div>
    );
};

export default App;
