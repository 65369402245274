import React, { useState, useEffect } from "react";
import "./../styles/Navbar.css";

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
            <div className="navbar-container">
                <a href="#hero" className="logo">
                    Vladimir Gaus | Cybersecurity Analyst & Data Scientist
                </a>
                <button
                    className={`hamburger ${isMenuOpen ? "open" : ""}`}
                    onClick={toggleMenu}
                    aria-label="Toggle navigation menu"
                >
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                </button>
                <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
                    <li>
                        <a href="#about" onClick={closeMenu}>
                            Über mich
                        </a>
                    </li>
                    <li>
                        <a href="#projects" onClick={closeMenu}>
                            Projekte
                        </a>
                    </li>
                    <li>
                        <a href="#certificates" onClick={closeMenu}>
                            Zertifikate
                        </a>
                    </li>
                    <li>
                        <a href="#skills" onClick={closeMenu}>
                            Fähigkeiten
                        </a>
                    </li>
                    <li>
                        <a href="#contact" onClick={closeMenu}>
                            Kontakt
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
