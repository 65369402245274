import React, { useEffect, useState } from "react";
import API from "../api";
import "../styles/Projects.css";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                console.log("API URL:", process.env.REACT_APP_API_URL);
                const response = await API.get("/api/projects/");
                console.log("Projects received:", response.data);
    
                const updatedProjects = response.data.map((project) => ({
                    ...project,
                    image: project.image
                        ? (project.image.startsWith("http")
                            ? project.image
                            : `${process.env.REACT_APP_API_URL}${project.image}`)
                        : "https://via.placeholder.com/300x200",
                }));
    
                setProjects(updatedProjects);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching projects:", err);
                console.error("Error details:", err.response?.data || err.message);
                setError("Fehler beim Laden der Projekte.");
                setLoading(false);
            }
        };
    
        fetchProjects();
    }, []);

    if (loading) {
        return <p className="loading-message">Daten werden geladen...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <section className="projects" id="projects">
            <h2 className="projects-title">Projekte</h2>
            <div className="project-grid">
                {projects.map((project) => (
                    <div className="project-card" key={project.id}>
                        <img
                            src={project.image}
                            alt={project.title || "Projektbild"}
                            className="project-image"
                        />
                        <div className="project-content">
                            <h3>{project.title || "Kein Titel verfügbar"}</h3>
                            <p className="project-description">
                                {project.description && project.description.length > 100
                                    ? `${project.description.substring(0, 100)}...`
                                    : project.description || "Keine Beschreibung verfügbar"}
                            </p>
                            {project.url ? (
                                <a
                                    href={project.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn primary"
                                >
                                    Mehr erfahren
                                </a>
                            ) : (
                                <p className="no-link">Kein Link verfügbar</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Projects;
