import React from "react";
import { FaGithub, FaLinkedin, FaXing, FaEnvelope } from "react-icons/fa"; 
import "../styles/Footer.css";

const Footer = ({ openModal }) => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <nav className="footer-navigation">
                    <a href="#about" className="footer-link">Über mich</a>
                    <a href="#projects" className="footer-link">Projekte</a>
                    <a href="#skills" className="footer-link">Fähigkeiten</a>
                    <a href="#contact" className="footer-link">Kontakt</a>
                </nav>

                <div className="footer-social">
                    <a href="https://github.com/GausCode" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaGithub />
                    </a>
                    <a href="https://www.linkedin.com/in/vladimir-gaus-7408a6177" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaLinkedin />
                    </a>
                    <a href="https://www.xing.com/profile/Vladimir_Gaus" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaXing />
                    </a>
                    <a href="mailto:venic89@gmail.com" className="social-icon">
                        <FaEnvelope />
                    </a>
                </div>

                <div className="footer-legal">
                    <button
                        type="button"
                        className="legal-link"
                        onClick={() => openModal("impressum")}
                    >
                        Impressum
                    </button>
                    <button
                        type="button"
                        className="legal-link"
                        onClick={() => openModal("datenschutz")}
                    >
                        Datenschutzerklärung
                    </button>
                </div>

                <div className="footer-copyright">
                    <p>&copy; {new Date().getFullYear()} Vladimir Gaus. Alle Rechte vorbehalten.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
