import React from "react";

const ImpressumContent = () => {
    return (
        <div>
            <h3>Impressum</h3>

            <h4>Angaben gemäß § 5 TMG</h4>
            <p>
                Vladimir Gaus<br />
                34393 Grebenstein<br />
                Deutschland<br />
                E-Mail: venic89@gmail.com
            </p>

            <h4>Kontakt</h4>
            <p>
                E-Mail: <a href="mailto:venic89@gmail.com">venic89@gmail.com</a>
            </p>

            <h4>Vertretungsberechtigter</h4>
            <p>
                Vladimir Gaus (Inhaber)
            </p>

            <h4>Haftung für Inhalte</h4>
            <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf
                diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
                TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
                Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>

            <h4>Haftung für Links</h4>
            <p>
                Unsere Website enthält Links zu externen Webseiten Dritter, auf deren Inhalte
                wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
                keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
                jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                erkennbar.
            </p>
            <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                entfernen.
            </p>

            <h4>Urheberrecht</h4>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche
                gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            </p>
            <p>
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                kommerziellen Gebrauch gestattet.
            </p>

            <h4>Streitschlichtung</h4>
            <p>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
            </p>

            <h4>Hinweis zum Datenschutz</h4>
            <p>
                Für datenschutzbezogene Anfragen beachten Sie bitte unsere{" "}
                <a href="/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
                    Datenschutzerklärung
                </a>.
            </p>
        </div>
    );
};

export default ImpressumContent;
