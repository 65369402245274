import React, { useState } from "react";
import axios from "axios";
import { FaEnvelope, FaUser, FaPaperPlane } from "react-icons/fa";
import "../styles/ContactForm.css";
import Modal from "./Modal";
import ImpressumContent from "./ImpressumContent";
import DatenschutzerklaerungContent from "./DatenschutzerklaerungContent";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [captchaLoaded, setCaptchaLoaded] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [responseMessage, setResponseMessage] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleConsentChange = (e) => {
        setConsentGiven(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (!captchaToken) {
            setError("Bitte bestätigen Sie, dass Sie kein Roboter sind.");
            setLoading(false);
            return;
        }

        if (!consentGiven) {
            setError("Bitte stimmen Sie den Datenschutzrichtlinien und dem Impressum zu.");
            setLoading(false);
            return;
        }

        axios
            .post("https://portfolionewbackend-d3e88d27ef4b.herokuapp.com/api/contact/", {
                ...formData,
                captchaToken,
            })
            .then((response) => {
                setResponseMessage(response.data.success);
                setError(null);
                setFormData({ name: "", email: "", message: "" });
                setConsentGiven(false);
            })
            .catch((error) => {
                setError(error.response?.data?.error || "Etwas ist schiefgelaufen.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCaptchaVerification = (token) => {
        setCaptchaToken(token);
    };

    const loadHCaptcha = () => {
        if (!captchaLoaded) {
            const script = document.createElement("script");
            script.src = "https://www.hcaptcha.com/1/api.js?render=explicit";
            script.async = true;
            script.onload = () => {
                // Initialisierung des hCaptcha-Widgets
                window.hcaptcha.render("hcaptcha", {
                    sitekey: "b73085a8-35b8-40d2-a520-8a50cb7248b8",
                    callback: handleCaptchaVerification,
                });
            };
            document.body.appendChild(script);
            setCaptchaLoaded(true);
        }
    };

    const openModal = (type) => {
        setModalOpen(true);
        if (type === "impressum") {
            setModalContent(<ImpressumContent />);
        } else if (type === "datenschutz") {
            setModalContent(<DatenschutzerklaerungContent />);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    return (
        <div className="contact-form-wrapper" id="contact">
            <form className="contact-form" onSubmit={handleSubmit} onFocus={loadHCaptcha}>
                <h2>
                    <FaEnvelope /> Kontaktformular
                </h2>
                <div className="form-group">
                    <label htmlFor="name">
                        <FaUser /> Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">
                        <FaEnvelope /> E-Mail:
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">
                        <FaPaperPlane /> Nachricht:
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group checkbox-group">
                    <input
                        type="checkbox"
                        id="consent"
                        name="consent"
                        checked={consentGiven}
                        onChange={handleConsentChange}
                        required
                    />
                    <label htmlFor="consent">
                        Ich stimme den{" "}
                        <button
                            type="button"
                            className="legal-link"
                            onClick={() => openModal("datenschutz")}
                        >
                            Datenschutzrichtlinien
                        </button>{" "}
                        und dem{" "}
                        <button
                            type="button"
                            className="legal-link"
                            onClick={() => openModal("impressum")}
                        >
                            Impressum
                        </button>{" "}
                        zu.
                    </label>
                </div>
                <div className="captcha-wrapper">
                    <div id="hcaptcha" className="h-captcha"></div>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? "Senden..." : "Senden"}
                </button>
                {responseMessage && <p className="success-message">{responseMessage}</p>}
                {error && <p className="error-message">{error}</p>}
            </form>

            {isModalOpen && <Modal content={modalContent} closeModal={closeModal} />}
        </div>
    );
};

export default ContactForm;
