import React, { useState } from "react";
import "../styles/Certificates.css";

// Standardbild zentral definiert
const DEFAULT_IMAGE = "/static/images/certificate.jpg";

const Certificates = ({ data }) => {
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Öffnet das Modal mit den Details eines Zertifikats
    const openModal = (certificate) => {
        setSelectedCertificate(certificate);
        setIsModalOpen(true);
    };

    // Schließt das Modal
    const closeModal = () => {
        setSelectedCertificate(null);
        setIsModalOpen(false);
    };

    // Fallback für fehlerhafte Bild-URLs
    const handleImageError = (e) => {
        e.target.src = DEFAULT_IMAGE;
    };

    return (
        <section className="certificates" id="certificates">
            <h2 className="certificates-title">Zertifikate</h2>
            <div className="certificate-grid">
                {data.map((certificate) => (
                    <div className="certificate-card" key={certificate.id}>
                        <img
                            src={certificate.image} // Bild-URL oder Standardbild
                            alt={certificate.title}
                            className="certificate-image"
                            onError={handleImageError} // Fallback für ungültige Bilder
                            onClick={() => openModal(certificate)} // Öffnet Modal bei Klick
                        />
                        <div className="certificate-content">
                            <h3 className="certificate-title">{certificate.title}</h3>
                            <p className="certificate-institution">{certificate.issuer}</p>
                            <p className="certificate-date">
                                Abschlussdatum:{" "}
                                {new Date(certificate.date_of_completion).toLocaleDateString("de-DE")}
                            </p>
                        </div>
                        {certificate.url ? (
                            <a
                                href={certificate.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn primary"
                            >
                                Zertifikat ansehen
                            </a>
                        ) : (
                            <p className="no-url">Keine URL verfügbar</p>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal für die Zertifikatsdetails */}
            {isModalOpen && selectedCertificate && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close" onClick={closeModal}>
                            &times;
                        </button>
                        <img
                            src={selectedCertificate.image || DEFAULT_IMAGE} // Bild oder Standardbild
                            alt={selectedCertificate.title}
                            className="modal-image"
                            onError={handleImageError} // Fallback für Modalbilder
                        />
                        <h3>{selectedCertificate.title}</h3>
                        <p className="modal-institution">
                            <strong>Institution:</strong> {selectedCertificate.issuer}
                        </p>
                        <p className="modal-date">
                            <strong>Abschlussdatum:</strong>{" "}
                            {new Date(selectedCertificate.date_of_completion).toLocaleDateString("de-DE")}
                        </p>
                        {selectedCertificate.url ? (
                            <a
                                href={selectedCertificate.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn primary"
                            >
                                Zertifikat ansehen
                            </a>
                        ) : (
                            <p className="no-url">Keine URL verfügbar</p>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default Certificates;
