import React, { useState, useEffect } from "react";
import "../styles/CookieBanner.css";

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setIsVisible(false);
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "declined");
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-banner">
                <div className="cookie-banner-content">
                    <p>
                        Diese Website verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu bieten. Weitere
                        Informationen finden Sie in unserer{" "}
                        <button
                            type="button"
                            className="legal-link"
                            onClick={() => window.open("#datenschutz", "_blank", "noopener,noreferrer")}
                        >
                            Datenschutzerklärung
                        </button>.
                    </p>
                    <div className="cookie-banner-actions">
                        <button className="accept-button" onClick={handleAccept}>
                            Akzeptieren
                        </button>
                        <button className="decline-button" onClick={handleDecline}>
                            Ablehnen
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default CookieBanner;
